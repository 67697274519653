import React, { Component } from "react"

class Footer extends Component {
  constructor() {
    super()
    this.state = {
      screenWidth: 0,
      viewboxHeight: 40,
      footerLineHeight: 10,
    }

    this.windowResizeHandler = this.windowResizeHandler.bind(this)
  }

  windowResizeHandler() {
    let viewboxHeight, footerLineHeight
    const { innerWidth } = window
    switch (true) {
      case innerWidth > 1200:
        viewboxHeight = 50
        footerLineHeight = 20
        break
      case innerWidth > 600:
        viewboxHeight = 50
        footerLineHeight = 20
        break
      default:
        viewboxHeight = 40
        footerLineHeight = 10
        break
    }
    this.setState({
      screenWidth: innerWidth,
      viewboxHeight,
      footerLineHeight,
    })
  }

  componentDidMount() {
    this.windowResizeHandler()
    window.addEventListener("resize", this.windowResizeHandler)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.windowResizeHandler)
  }

  render() {
    const { viewboxHeight, screenWidth, footerLineHeight } = this.state

    return (
      <footer className="footer-wrapper">
        <svg
          className="footer-bg"
          width="100%"
          height={viewboxHeight}
          viewBox={`0 0 ${screenWidth} ${viewboxHeight}`}
        >
          <linearGradient
            id="SVGID_2_"
            gradientUnits="userSpaceOnUse"
            x1={screenWidth / 2}
            y1="-5"
            x2={screenWidth / 2}
            y2={viewboxHeight * 0.7}
          >
            <stop offset="0" style={{ stopColor: "#FFDA74" }} />
            <stop offset="1" style={{ stopColor: "#FDBA12" }} />
          </linearGradient>
          <path
            fill="url(#SVGID_2_)"
            d={`M0,${footerLineHeight} L0,${viewboxHeight} L${screenWidth},${viewboxHeight} L${screenWidth},${footerLineHeight} L${screenWidth *
              0.8},${footerLineHeight} C${screenWidth * 0.62},0,${screenWidth /
              2},0,${screenWidth / 2},0 C${screenWidth * 0.5},0,${screenWidth *
              0.38},0,${screenWidth * 0.2},${footerLineHeight}`}
          />
        </svg>
        <div className="footer-content">
          <span>
            ©{new Date().getFullYear()} Simet, todos los derechos reservados.
          </span>
          <div className="footer-credit">Desarrollo Websnap</div>
        </div>
      </footer>
    )
  }
}

export default Footer
