import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import MobileMenu from "./MobileMenu"
import "../../src/styles/index.scss"

const Layout = props => {
  return (
    <div className="layout-wrapper">
      <MobileMenu />
      <Header path={props.location} />
      <div className="layout-content">
        <main>{props.children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
