import React, { Component } from "react"
import { Link } from "gatsby"
import { gsap } from "gsap"

import logoWeb from "../images/logo-simet-web.png"
import logoWebNaranja from "../images/logo-simet-naranja-web.png"
import logoUsach from "../images/logo-usach.png"

const menuItems = [
  {
    id: "8eee96749f0c77d9afbfab527ddd54af",
    title: "Acerca",
    route: "/acerca",
    children: null,
  },
  {
    id: "3b735d5300dd7f34f723f9b58c2aec14",
    title: "Servicios",
    route: "/servicios",
    children: [
      {
        id: "639043b8703faa773027a5f66058d79b",
        title: "Ensayos de Laboratorio",
        route: "/ensayos_laboratorio",
      },
      {
        id: "da6f5c399f034dc87c811fbe2ce8a1cb",
        title: "Análisis de Falla",
        route: "/analisis_falla",
      },
      {
        id: "222469ddee0332e51710d516cb7e1616",
        title: "Ingeniería Inversa",
        route: "/ingenieria_inversa",
      },
      {
        id: "8ccbb530dba34afc6786bcdedf7f5623",
        title: "Visita a Terreno",
        route: "/visita_terreno",
      },
    ],
  },
  {
    id: "785801e5ab29add74982d7a1603d1499",
    title: "Certificación de Productos",
    route: "/certificacion",
    children: [
      {
        id: "1251666d57fc3ea49a26e2206c792510",
        title: "Aceros Estructurales",
        route: "/certificacion_aceros",
      },
      {
        id: "ca8d9fae359464d492b7f1d68348451f",
        title: "Tubular Cerrado",
        route: "/certificacion_tubular",
      },
      {
        id: "f34df5dd5e2fc3fe047e8d09bc4e795a",
        title: "Perfil Abierto",
        route: "/certificacion_abierto",
      },
    ],
  },
  {
    id: "159f41b3c45431e3f8f473d4e107e144",
    title: ["Informes"],
    route: "/informes",
    children: null,
    split: true,
  },
  {
    id: "c525e22c0d61e483cf2857ea5368358c",
    title: "Contacto",
    route: "/contacto",
    children: null,
  },
]

class Header extends Component {
  constructor() {
    super()

    this.subMenus = {}
    this.subMenusTweens = {}

    this.submenuToggleHandler = this.submenuToggleHandler.bind(this)
    this.windowSizeHandler = this.windowSizeHandler.bind(this)

    this.windowWidth = null
  }

  submenuToggleHandler(e) {
    e.stopPropagation()
    const targetId = e.target.getAttribute("data-target")
    for (let id in this.subMenusTweens) {
      if (id !== targetId) this.subMenusTweens[id].reverse()
    }
    const targetTween = this.subMenusTweens[targetId]
    targetTween.reversed(!targetTween.reversed())
  }

  windowSizeHandler() {
    this.windowWidth = window.innerWidth
  }

  componentDidMount() {
    this.windowSizeHandler()
    window.addEventListener("resize", this.windowSizeHandler)

    for (let id in this.subMenus) {
      this.subMenusTweens[id] = gsap
        .to(this.subMenus[id], {
          duration: 0.2,
          paused: true,
          y: 0,
          autoAlpha: 1,
        })
        .reverse()
    }
    window.onclick = () => {
      for (let id in this.subMenusTweens) {
        this.subMenusTweens[id].reverse()
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.windowSizeHandler)
  }

  render() {
    const { path } = this.props
    return (
      <header className="header-wrapper">
        <div className="header-content d-flex">
          <div className="logo-wrapper">
            <Link to="/">
              <img
                className="img-fluid logo"
                src={
                  path
                    ? path.indexOf("certificaciones") > 0
                      ? logoWebNaranja
                      : logoWeb
                    : logoWeb
                }
                alt="Simet"
              />
            </Link>
          </div>
          <nav className="menu-wrapper flex-grow-1 d-flex justify-content-end">
            <ul className="nav">
              {menuItems.map((e, i) => {
                const locationMatch = path === e.route
                if (e.children !== null) {
                  return (
                    <li className="nav-item position-relative" key={e.id}>
                      <button
                        href="#"
                        className={`btn btn-link dropdown-toggle nav-link${
                          locationMatch ? " active" : ""
                          }`}
                        data-target={e.id}
                        onClick={this.submenuToggleHandler}
                      >
                        {e.title}
                      </button>
                      <div
                        className="dropdown-menu"
                        ref={div => (this.subMenus[e.id] = div)}
                      >
                        {e.children.map(child => (
                          <Link
                            key={child.id}
                            className="dropdown-item"
                            to={child.route}
                          >
                            {child.title}
                          </Link>
                        ))}
                      </div>
                    </li>
                  )
                } else {
                  return (
                    <li className="nav-item" key={e.id}>
                      <Link
                        className={`nav-link text-center${
                          locationMatch ? " active" : ""
                          }`}
                        to={e.route}
                      >
                        {typeof e.title === "string"
                          ? e.title
                          : e.title.map((text, index) => (
                            <div key={text + "-" + index}>{text}</div>
                          ))}
                      </Link>
                    </li>
                  )
                }
              })}
            </ul>
            <a
              href="https://usach.cl"
              target="_blank"
              rel="noopener noreferrer"
              className="usach-link"
            >
              <img
                src={logoUsach}
                alt="USACH"
                className="img-fluid h-100"
                title="USACH"
              />
            </a>
          </nav>
        </div>
      </header>
    )
  }
}

export default Header
