import React, { Component } from "react"
import ReactDOM from "react-dom"
import { Link } from "gatsby"
import { gsap } from "gsap"

const menuItems = [
  {
    id: "8681c83f84348410c7b35838f9072e97",
    title: "Home",
    route: "/",
    children: null,
  },
  {
    id: "8eee96749f0c77d9afbfab527ddd54af",
    title: "Acerca",
    route: "/acerca",
    children: null,
  },
  {
    id: "3b735d5300dd7f34f723f9b58c2aec14",
    title: "Servicios",
    route: "/servicios",
    children: [
      {
        id: "639043b8703faa773027a5f66058d79b",
        title: "Ensayos de Laboratorio",
        route: "/ensayos_laboratorio",
      },
      {
        id: "da6f5c399f034dc87c811fbe2ce8a1cb",
        title: "Análisis de Falla",
        route: "/analisis_falla",
      },
      {
        id: "222469ddee0332e51710d516cb7e1616",
        title: "Ingeniería Inversa",
        route: "/ingenieria_inversa",
      },
      {
        id: "8ccbb530dba34afc6786bcdedf7f5623",
        title: "Visita a Terreno",
        route: "/visita_terreno",
      },
    ],
  },
  {
    id: "785801e5ab29add74982d7a1603d1499",
    title: "Certificación de Productos",
    route: "/certificacion",
    children: [
      {
        id: "1251666d57fc3ea49a26e2206c792510",
        title: "Aceros Estructurales",
        route: "/certificacion_aceros",
      },
      {
        id: "ca8d9fae359464d492b7f1d68348451f",
        title: "Tubular Cerrado",
        route: "/certificacion_tubular",
      },
      {
        id: "f34df5dd5e2fc3fe047e8d09bc4e795a",
        title: "Perfil Abierto",
        route: "/certificacion_abierto",
      },
    ],
  },
  {
    id: "159f41b3c45431e3f8f473d4e107e144",
    title: ["Informes"],
    route: "/informes",
    children: null,
    split: true,
  },
  {
    id: "c525e22c0d61e483cf2857ea5368358c",
    title: "Contacto",
    route: "/contacto",
    children: null,
  },
]

class MobileMenu extends Component {
  constructor() {
    super()

    this.state = {
      submenuOpen: false,
    }

    this.menuTween = gsap.timeline({ paused: true });
    this.menuBars = {};
    this.menuWrapper = null;

    this.menuIconClickHandler = this.menuIconClickHandler.bind(this)
    this.menuItemClickHandler = this.menuItemClickHandler.bind(this)
    this.submenuOpenHandler = this.submenuOpenHandler.bind(this)
  }

  menuItemClickHandler() {
    this.menuTween.reverse()
    document.body.classList.remove("no-scroll")
    this.setState({ submenuOpen: null })
  }

  menuIconClickHandler() {
    this.menuTween.reversed(!this.menuTween.reversed())
    document.body.classList.toggle("no-scroll")
    this.setState({ submenuOpen: false })
  }

  submenuOpenHandler(e) {
    e.stopPropagation()
    const targetId = e.target.getAttribute("data-target")
    this.setState({
      submenuOpen: this.state.submenuOpen === targetId ? null : targetId,
    })
  }

  componentDidMount() {

    const { topBar, middleBar, bottomBar } = this.menuBars
    gsap.set(this.menuWrapper, {
      xPercent: -101,
    })
    this.menuTween
      .to(topBar, { duration: 0.2, x: 52, stroke: "#ffffff", rotation: 45 })
      .to(middleBar, { duration: 0.1, alpha: 0 }, 0)
      .to(
        bottomBar,
        { duration: 0.2, x: 52, stroke: "#ffffff", rotation: -45 },
        0
      )
      .to(this.menuWrapper, { xPercent: 0, duration: 0.3 }, 0)
      .reverse()
  }

  render() {
    console.log("render!!!");
    if (
      typeof window !== "undefined" &&
      window.document &&
      window.document.createElement
    ) {
      return ReactDOM.createPortal(
        <div id="menuWraparound" className="mobile-menu-full">
          <div
            id="mobileMenuWrapper"
            className="mobile-menu-wrapper"
            ref={e => (this.menuWrapper = e)}
          >
            <nav className="some-class">
              <ul className="menu-container another">
                {menuItems.map(item => (
                  <li key={`m-${item.id}`} className="menu-item">
                    {item.children ? (
                      <>
                        <button
                          className="menu-link has-submenu"
                          onClick={this.submenuOpenHandler}
                          data-target={item.id}
                        >
                          {item.title}
                        </button>
                        <ul
                          className={`submenu-wrap${
                            this.state.submenuOpen === item.id ? " open" : ""
                            }`}
                        >
                          {item.children.map(submenuItem => (
                            <li key={submenuItem.id}>
                              <Link
                                className="submenu-link"
                                to={submenuItem.route}
                                onClick={this.menuItemClickHandler}
                              >
                                {submenuItem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                        <Link
                          to={item.route}
                          className="menu-link"
                          onClick={this.menuItemClickHandler}
                        >
                          {item.title}
                        </Link>
                      )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <svg
            className="menu-btn"
            // height="384pt"
            viewBox="0 0 384 276"
            // width="384pt"
            xmlns="http://www.w3.org/2000/svg"
            onClick={this.menuIconClickHandler}
          >
            <line
              x1="16"
              y1="17"
              x2="368"
              y2="17"
              ref={e => (this.menuBars["topBar"] = e)}
            />
            <line
              x1="16"
              y1="139.2"
              x2="368"
              y2="139.2"
              ref={e => (this.menuBars["middleBar"] = e)}
            />
            <line
              x1="16"
              y1="261"
              x2="368"
              y2="261"
              ref={e => (this.menuBars["bottomBar"] = e)}
            />
          </svg>
        </div>,
        document.getElementById("test")
      )
    } else {
      return "";
    }
  }
}

export default MobileMenu
